import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout';
import TextBanner from "../components/text-banner/text-banner"

class Error extends React.Component {
  render() {
    const errorTextBanner = get(this, 'props.data.errorTextBanner');
    return (
      <Layout location={this.props.location}>
        <Seo title="Page not found" />
          <div className="container-fluid container-fluid--page" style={{ marginBottom: "200px" }}>
            <TextBanner textOne={errorTextBanner.textOne} textTwo={errorTextBanner.textTwo} />
          </div>
      </Layout>
    )
  }
}

export default Error;

export const query = graphql`
query errorQuery {
  errorTextBanner: contentfulTextBanner(textBannerName: {eq: "errorTextBanner"}) {
    textBannerName
    textOne
    textTwo
  }
}
`
